<template>
  <div class="register">
<div class="register-container" :class="classregister" :style="styleregister">
  
<v-form  ref="form" v-model="valid" lazy-validation>
  <v-card
  class="mx-auto"
  max-width="344"
  outlined
  raised
  v-show="e1===1"
  >
  <v-card-title primary-title>
    <div>
        <img class="logo" src="@public/img/logo_login.png" />
    </div>
  </v-card-title>
<v-alert type="success" dismissible v-if="nomeAllenatore">
    Sei stato invitato da <br>
    <strong>{{nomeAllenatore}}</strong>
</v-alert>
<v-alert type="success" dismissible v-if="message && message.length">
  {{message}}
</v-alert>
<v-card>
    <v-card-title >
    Nuova registrazione
    <template v-if="register.profilo">
    </template>  
  </v-card-title>
  <v-card
  class="mb-12"
  >
  <v-card-text v-if="!nomeAllenatore" class="select-profilo">
    <v-radio-group required v-model="register.profilo">
      <v-radio
        v-for="option in options.profilo"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
  </v-card-text>
  <v-card-text v-show="register.profilo" class="scroll">
  <v-text-field
  v-model="register.nome"
  :rules="[rules.required]"
  label="Nome"
  autocomplete
  required
  ></v-text-field>

  <v-text-field
  v-model="register.cognome"
  :rules="[rules.required]"
  label="Cognome"
  autocomplete
  required
  ></v-text-field>

  <v-text-field
  v-model="register.email"
  :rules="emailRules"
  label="E-mail"
  autocomplete="username"
  type="email"
  required
  ></v-text-field>

  <v-text-field
  v-model="register.password"
  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
  :rules="[rules.required, rules.min, checkPassword]"
  :type="show1 ? 'text' : 'password'"
  label="Password"
  autocomplete="new-password"
  @click:append="show1 = !show1"
  ></v-text-field>

  <v-text-field
  v-model="register.confirm"
  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
  :rules="[rules.required, rules.min, checkPassword]"
  :type="show1 ? 'text' : 'password'"
  label="Conferma Password"
  autocomplete="new-password"
  @click:append="show2 = !show2"
  ></v-text-field>
  </v-card-text>
  </v-card>

  <v-card v-show="register.profilo"
  class="mb-12"
  >
<v-card-title>
Consensi obbligatori
</v-card-title>
<v-card-subtitle>
Necessari per poter erogare il servizio.
</v-card-subtitle>
  <v-card-text>

  <v-checkbox required :rules="[rules.required]" v-for="consenso in consensi" :key="consenso.id"
  v-model="register.consenso[consenso.id]"
  ><template v-slot:label><span v-html="consenso.label"></span></template></v-checkbox>
  </v-card-text>
<v-card-title>
Consensi facoltativi
</v-card-title>
<v-card-subtitle>
Utili per restare aggiornati sulle ultime novità.
</v-card-subtitle>
  <v-card-text>
  trattamento dei miei dati personali per la finalità di marketing diretto di cui al <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >punto 4.B</a> dell’informativa.
  <v-radio-group
  v-model="register.consenso.marketing_diretto"
  :rules="[rules.required]"
  >
  <v-radio
  label="Esprimo il consenso"
  value="si"
  ></v-radio>
  <v-radio
  label="Non esprimo il consenso"
  value="no"
  ></v-radio>
  </v-radio-group>
  trattamento dei miei dati personali per la finalità di marketing di cui al <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >punto 4.C</a>
  <v-radio-group
  v-model="register.consenso.marketing_terzi"
  :rules="[rules.required]"
  >
  <v-radio
  label="Esprimo il consenso"
  value="si"
  ></v-radio>
  <v-radio
  label="Non esprimo il consenso"
  value="no"
  ></v-radio>
  </v-radio-group>
  <v-card-actions>

<v-spacer></v-spacer>
  <v-btn color="primary"
  :disabled="!valid"
  @click="submit"
  >
  Registrati
  </v-btn>
  
  </v-card-actions>
  </v-card-text>
  </v-card>

  
</v-card>
<v-card-actions>
  <v-spacer></v-spacer>
  <p class="text-small">
  Hai già un account?
  <v-btn x-small @click="login">Accedi</v-btn>
  </p>
</v-card-actions>

</v-card>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
        OOPS...
        </v-card-title>

        <v-card-text>
            <span v-html="error"></span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
          Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-form>
</div>

</div>

</template>

<script type="module">
import axios from 'axios'
import env from "@mixins/environment.js"
import prompt from "@mixins/promptPWA.js"
import version from "@mixins/version.js"
export default {
  mixins: [
    env,
    prompt,
    version,
  ],
  data: () => ({
      isLoading: false,
      nomeAllenatore: null,
        options: {
            profilo: [
                {
                    "value": "AT",
                    "label": "Atleta",
                },
                {
                    "value": "AL",
                    "label": "Allenatore",
                },
            ],
        },
      message: "",
      e1: 1,
      consensi: [
        {
            "label": "Dichiaro di aver letto i termini e le condizioni di servizio  (visionabili al seguente <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >link</a>) e di accettarli integralmente",
            "id": "servizio",
        },
        {
            "label": "Dichiaro di accettare espressamente <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >l’articolo 2</a> (oggetto del contratto e responsabilità) dei termini e condizioni",
            "id": "termini",
        },
        {
            "label": "Esprimo il consenso al trattamento dei miei dati personali indicati al <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >punto 3</a> dell’informativa, inclusi dati medici e relativi alla salute, per la finalità di cui al punto <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >4.A</a> dell’informativa.",
            "id": "salute",
        },
        {
            "label": "Dichiaro di aver letto l’informativa per il trattamento dei dati personali (visionabile al seguente <a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >link</a>) e alla luce di questa",
            "id": "personali",
        },
        {
            "label": "Dichiaro di accettare espressamente l’<a onclick='event.stopPropagation()' href='https://www.coachpeaking.com/termini/'  segmento='_blank' rel='noreferrer noopener' >articolo 6</a> (legge applicabile e tribunale competente) dei termini e condizioni",
            "id": "tribunale",
        },
      ],
    dialog: false,
    prompt: false,
    classregister: 'mv-1',
    styleregister: 'background-position:'+Math.floor(Math.random()*100)+'% 100%',
    valid: false,
    register: {
        otp: '',
      nome : '',
      cognome : '',
      profilo : null,
      email : '',
      password : '',
      confirm : '',
      invito_token: '',
      consenso: {
          "servizio": 0,
          "termini": 0,
          "salute": 0,
          "personali": 0,
          "tribunale": 0,
          "marketing_diretto": false,
          "marketing_terzi": false,
      },

    },
    rules: {
      required: value => !!value || 'Richiesto.',
      min: v => (v && v.length >= 5) || 'Min 5 caratteri',
    },
    error: false,
    emailRules: [
      v => !!v || 'E-mail obbligatoria',
      v => /.+@.+/.test(v) || 'L\'e-mail deve essere valida'
    ],
    select: null,
    show1: false,
    show2: false,
  }),

    mounted: function () {
        this.init();
    },
    computed: {

        checkPassword: function () {
            return ((!!this.register.password && (this.register.password === this.register.confirm)) || 'La password è diversa dalla conferma');
        }
    },
    methods: {

        init: function () {
            this.checkInvito();
            this.$refs.form.validate();
        },

        checkInvito: async function () {
            if (this.$route.query.q === "invito") {
                this.register.profilo = "AT";
                this.register.email = this.$route.query.active_email;
                this.register.invito_token = this.$route.query.token;
                let allenatore = await this.getInvito(this.register.invito_token);
                if (allenatore) {
                    this.nomeAllenatore = allenatore;
                }
            }
        },

        formatMessage: function (message) {
            if (!(message && message.length)) {
                return false;
            }
            let re = /__BR__/gi; 
            message = message.replace(re,"<br>");
            return  message;
        },
        
        clearMessage: function () {
            this.setMessage(false);
        },

        setMessage: function (message) {
            this.error = this.formatMessage(message);
            this.updateDialog();
        },

        updateDialog: function () {
            this.dialog = (this.error) ? true : false;
        },

        update: function () {
            window.location.reload(true)
        },

        abortInstall: function () {
            this.prompt = false;
        },

        install: function () {
            this.dialog=true;
            prompt.install()
            .then(
            (result)=>{
                if(result.success) {
                    this.prompt=false;
                }
            },
            (error)=>{
                console.warn(error);
            }
            )
        },

        getInvito: function (token) {
            return new Promise((resolve)=>{
                const url = this.api_base_url+'/api/v2/allenatore_atleta.php?invito='+token;
                axios({
                    method: 'GET',
                    url: url,
                })
                .then( result=>{
                    if (result.data && result.data.success) {
                        let allenatore = result.data.data;
                        resolve(`${allenatore.nome} ${allenatore.cognome}`);
                    } else {
                        resolve(false);
                    }
                })
            });
        },

        confirmEmailWithOtp: async function () {
            const email = this.register.email;
            const type = 'conferma';
            const result = await this.sendOtp(email, type);
            if (result.success) {
                this.openOtpConfirm(this.register.email);
            } else {
                this.setMessage(result.message.text);
            }
        },

        sendOtp: function (email, type) {
            let data = {
                "email": email,
                "type": type,
            }
            return new Promise((resolve)=>{
                const url = this.api_base_url+'/api/v2/utente.php?otp';
                axios({
                    method: 'POST',
                    url: url,
                    data: data
                })
                .then(
                (response) => {
                    let result = response.data;
                    resolve(result);
                },
                (error)=>{
                    this.setMessage(error.message.text);
                    resolve({success:false});
                }
                );
            });
        },

        addUtente: function () {
            this.isLoading = true;
            return new Promise((resolve)=>{
                const url = this.api_base_url+'/api/v2/utente.php';
                const data  = this.register;
                axios({
                    method: 'POST',
                    url: url,
                    data: data
                })
                .then(
                (response) => {
                    let result = response.data;
                    this.isLoading = false;
                    if (result.success) {
                        resolve(result);
                    } else {
                        this.setMessage(result.message.text);
                        resolve(result);
                    }
                },
                () => {
                    this.isLoading = false;
                    resolve({success:false});
                }
                );
            });
        },

        cleanRegisterOtp: function () {
            this.register.otp = "";
        },

        openOtpConfirm: function (email) {
            let query = {
                'email': email,
            };
            this.$router.replace({ path: '/otp/confirm', query: query})
        },

        submit: async function () {
            if (this.$refs.form.validate()) {
                let utenteAggiunto = await this.addUtente();
                if (utenteAggiunto.success) {
                    this.confirmEmailWithOtp();
                } else {
                    this.setMessage(utenteAggiunto.message.text);
                }
            }
        },

        clear: function () {
            this.clearMessage();
            this.$refs.form.reset()
        },

        login: function () {
            this.$router.replace("/");
        },
    }
}
</script>

<style lang="scss">
@keyframes MOVE-0 {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 100%;
  }
}
@keyframes MOVE-1 {
  from {
    background-position: 100% 100%;
  }
  to {
    background-position: 0% 0%;
  }
}
.register {
  height: 100%;
}
.install-icon {
  display: inline-block;
  width: 2em;
  vertical-align: bottom;
}
.icona-browser {
  display: inline-block;
  width: 3em;
  vertical-align: bottom;
}
.register-container {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  background: var(--col-msp)  url("~@public/img/bg-home.jpg") no-repeat 50% 50%;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: center;
  overflow-y: auto;
  .select-profilo {
      padding-top: 0;
      padding-bottom: 0;
        .v-item--active {
            font-weight: bold;
            background: var(--col-msp);
            border-radius: 30px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
.v-label.error--text {
  color: rgba(0,0,0,0.6) !important;
}
  .update {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .v-btn {
      background: #fffa;
    }
    .version {
      font-size: 10px;
      color: #fff;
      background: #0006;
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }
  .install {
    margin: 10px auto;
    text-align: center;
    img {
      margin-bottom: 10px;
    }
  }
}
.v-dialog {
  position: relative;
  dt {
    border-top: 5px solid #eee;
    margin-top: 5px;
    padding-top: 5px;
    font-weight: bold;
    img {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
  }

}
</style>
